body {
  margin: 0;
  line-height: 28px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  font-family: "Antic Slab", serif;
  background: #f3f3f3;
}

a.bm-item.nav-link.menu-item {
  padding-left: 16px !important;
}

a.bm-item.nav-link.menu-item.active {
  color: #fff !important;
}

a.nav-link.dropdown-toggle.for-mobile {
  padding-left: 16px;
}

ul#collapseExample1 {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

/* =======SideNavbar========= */
svg {
  font-size: 21px;
  color: #7f7e7e;
  margin-bottom: 6px;
}

svg.icon {
  font-size: 20px;
}

div#menuIcon {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  font-size: 30px;
}

i.fas.fa-bars {
  border: 1px solid #6d6a6a;
  padding: 1px 4px;
  color: #555353;
}

.fixed-menu {
  position: fixed;
  box-shadow: -6px 0px 20px rgb(0 0 0 / 10%);
  right: 0;
  top: 0;
  z-index: 999;
  background: #fff;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.fixed-menu::-webkit-scrollbar {
  width: 4px;
  background: #fff;
}

.fixed-menu::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background: #fff;
}

.fixed-menu::-webkit-scrollbar-thumb {
  background: #8f969c;
  border-radius: 10px;
}

.fixed-menu::-webkit-scrollbar-thumb:hover {
  background: #8f969c;
}

.nav-link {
  color: #3d3d3d;
}

.navbar-nav a.nav-link {
  padding: 12px 16px;
  font-size: 14px;
  margin: 1px 0;
}

a.dropdown-item.nav-link {
  padding: 6px 2px 6px 24px !important;
  font-size: 13px;
  font-weight: bold;
}

a.dropdown-item.nav-link.active {
  padding: 6px 2px 6px 38px;
  margin: 2px 0;
  font-weight: 300;
  font-size: 14px;
  background-color: #a3a3a3;
}

a.dropdown-item.nav-link.active svg {
  color: #fff;
  background: #a3a3a3;
}

a.nav-link:hover {
  color: #fff;
  background: #808080;
}

a.nav-link:hover svg {
  font-size: 21px;
  color: #fff;
}

a.nav-link.active {
  color: #fff;
  background: #808080;
}

a.nav-link.active svg {
  color: #fff;
  background: #808080;
}

.dropdown-menu {
  border: 0;
  padding: 0;
}

a.dropdown-item:hover {
  color: #fff;
  background: #808080;
}

a.dropdown-item:hover svg {
  font-size: 21px;
  color: #fff;
}

.main .col-lg-11 {
  padding-right: 0;
}

.main .col-lg-1 {
  padding-left: 0;
  padding-right: 0;
}

.sidelogo {
  padding: 12px 0 12px 6px;
}

.navbar-nav {
  padding: 0;
}

.nav-link:focus {
  color: unset;
}

button.btn.Menu {
  margin-top: -7px;
  position: absolute;
  top: 18px;
  left: 116px;
  float: right;
  border: 1px solid #ccc;
  padding: 0px 5px 3px 5px;
}

i.fas.fa-sort-down {
  color: #3d3d3d;
}

button.btn.Menu:focus {
  box-shadow: unset;
}

@media (min-width: 576px) {
  div#menuIcon {
    display: none;
  }

  div#menuBar {
    display: block;
    margin-top: 30px;
  }

  a.nav-link.active {
    width: 100%;
  }
}

@media (max-width: 826px) {
  .col-md-10.left {
    padding-left: 0;
    padding-right: 0;
  }

  .col-md-2.right {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 776px) {
  .col-sm-10.left {
    padding-left: 0;
    padding-right: 0;
    width: 80%;
  }

  .col-sm-2.right {
    padding-left: 0;
    padding-right: 0;
    width: 20%;
  }
}

@media (max-width: 576px) {
  div#menuIcon {
    display: block;
  }

  div#menuBar {
    display: none;
  }

  .col-sm-10.left {
    padding-left: 0;
    padding-right: 0;
    width: 97.9%;
  }

  .col-sm-2.right {
    padding-left: 0;
    padding-right: 0;
    width: 2.1%;
  }

  /* .col-11 {
    width: 92.9%;
  }

  .col-1 {
    width: 0.1%;
  } */
}

@media (min-width: 1200px) {
  .col-xl-10 {
    width: 88%;
  }

  .col-xl-2 {
    width: 12%;
  }
}

@media (max-width: 1251px) {
  .navbar-nav a.nav-link {
    padding: 12px 17px;
  }
}

@media (max-width: 1199px) {
  .navbar-nav a.nav-link {
    padding: 12px 26px;
    margin: 1px 0;
  }
}

@media (max-width: 897px) {
  .navbar-nav a.nav-link {
    padding: 12px 22px;
  }
}

@media (max-width: 850px) {
  .navbar-nav a.nav-link {
    padding: 12px 20px;
  }
}

@media (max-width: 776px) {
  .navbar-nav a.nav-link {
    padding: 10px 20px;
  }
  button.btn.Menu {
    top: 17px;
    left: 108px;
  }

}

@media (max-width: 756px) {
  button.btn.Menu {
    left: 118px;
  }
}

@media (max-width: 676px) {
  button.btn.Menu {
    left: 110px;
  }
}

@media (max-width: 644px) {
  button.btn.Menu {
    left: 102px;
  }
}

@media (max-width: 608px) {
  .navbar-nav a.nav-link {
    padding: 12px 20px;
  }
  button.btn.Menu {
    left: 96px;
  }
}

@media (max-width: 576px) {
  button.btn.Menu {
    left: 118px;
    top: 22px;
}
}

.bm-item.menu-item:hover svg {
  color: #fff !important;
  background: #808080;
}

/* =======Common======= */
h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5;
}

p {
  margin-bottom: 0;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #000;
}

.Imgresize {
  position: relative;
}

.ImgViewer {
  padding-top: 56.25%;
  margin: 0;
}

.FixingRatio {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ImgRatio {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.img100 {
  width: 100%;
}

.margintop12 {
  margin-top: 8rem;
}

a.btn.ntn-primary {
  background: #808080;
  color: #fff;
}

.top-banner-btn {
  text-align: center;
  margin-top: 90px;
}

@media (max-width: 728px) {
  .top-banner-content {
    margin-top: 20px;
    position: inherit !important;
    padding: 0 !important;
    left: unset !important;
  }
}

@media (max-width: 575px) {
  .top-banner-btn {
    margin-top: 10px;
  }
}

/* banner */
.top-banner {
  background: url("https://backoffice.cloud-bistro.com/uploads/cb-banner-platter.webp");
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-banner {
  position: relative;
}

.top-banner-content {
  position: absolute;
  background: #ffffffa8;
  padding: 50px 32px;
  bottom: 0%;
  left: 40%;
}

.top-banner-content h1 {
  font-family: "Pacifico", cursive;
}

.top-banner-content p.brief {
  color: #000;
  text-transform: capitalize;
  font-weight: bold;
}

.margin-bottom5 {
  margin-bottom: 12rem;
}

/* .............Topfoodsection............... */
.TopFoodSection-content {
  margin-top: 0px;
  padding: 30px 60px;
  text-align: justify;
}

.TopFoodSection-content h2 {
  font-family: "Pacifico", cursive;
  padding: 20px 20px;
}

.Topfoodsection-img {
  position: relative;
}

.Topfoodsection-img .img-2 {
  /* position: absolute;
  bottom: -41%;
  left: -9%; */
  margin-left: -40px;
  margin-top: -50px;
}

.img-2 .img100 {
  width: 356px;
}

@media (max-width: 1228px) {
  .Topfoodsection-img .img-2 {
    bottom: -56%;
  }
}

@media (max-width: 1142px) {
  .Topfoodsection-img .img-2 {
    bottom: -72%;
  }
}

@media (max-width: 980px) {
  .TopFoodSection-content {
    padding: 1px 32px;
  }

  .TopFoodSection-content h1 {
    padding: 14px 0;
  }

  .Topfoodsection-img .img-2 {
    position: unset !important;
    margin-top: 10px;
  }

  .margintop12 {
    margin-top: 1rem;
  }
}

@media (max-width: 767px) {
  .TopFoodSection-content {
    padding: 40px 12px;
    margin-bottom: 12px;
  }

  .img-2 .img100 {
    width: 100%;
  }
}

@media (max-width: 728px) {
  .top-banner-content {
    top: 65%;
    padding: 4px 30px !important;
  }
}

@media (max-width: 728px) {
  .TopFoodSection-content {
    margin-top: 1rem;
    padding: 0 8px 0 20px;
    margin-bottom: 12px;
  }
}

.TopFoodSection-content a.btn.btn-primary {
  background: #fff;
  color: #000;
  border: 2px solid #808080;
  border-radius: 0;
  margin-top: 20px;
}

.TopFoodSection-content a.btn.btn-primary:hover {
  background: #808080;
  color: #fff;
  border-radius: 0;
  margin-top: 20px;
}

/* =======Menu part======== */
.Heading,
.Heading-name {
  text-align: center;
  text-transform: capitalize;
}

.Heading-name {
  font-family: "Pacifico", cursive;
  text-decoration: underline;
}

.dish-menu {
  width: 100%;
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  -moz-box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-height: 8rem;
}

.dish-menu:hover {
  background: #555454;
  color: #fff;
}

.dish-menu p {
  font-size: 14px;
  line-height: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dish-menu h5 {
  text-transform: capitalize;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dish-menu p {
  text-transform: capitalize;
}

.menuImg {
  margin-right: 20px;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.dish-details {
  background: #fff;
  color: #000;
  border: 2px solid #808080;
  border-radius: 0;
  font-size: 14px;
  font-weight: bold;
}

.dish-details:hover {
  background: #fef5db;
  color: #2d2d2d;
  border: 2px solid #efd688;
}

button.btn.btn-warning {
  border-radius: 0;
  background: #fff;
  border-color: #e7e6e6;
  color: #a5a5ab;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0px 1px 20px -12px rgb(0 0 0);
}

#MenuCate button.btn.btn-warning {
  margin: 4px 12px;
}

button.btn.btn-warning:hover {
  background: #808080;
  color: #fff;
  font-weight: normal;
}

.Tab-heading {
  text-align: center;
}

.Tab-heading h5 {
  color: #7d7d7d;
}

.Tab-heading button.btn.btn-warning {
  color: #fff;
  background: #d7aa50;
  border: 2px solid #bf9f60;
  font-size: 20px;
  font-weight: 500;
}

.Tab-heading button.btn.btn-warning:hover {
  border: 2px solid #bf9f60;
  color: #bf9f60;
  background: #fff;
}

@media (max-width: 1069px) {
  .px-5 {
    padding-right: 1rem !important;
    padding-left: 2rem !important;
  }
}

@media (max-width: 767px) {
  .MenuPart.margintop12 {
    margin-top: 3rem;
  }

  .margin-bottom5 {
    margin-bottom: 0rem;
  }

  .mb-5 {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 575px) {
  .col-sm-3.menu-img {
    width: 22%;
    padding-right: 0;
  }

  .col-sm-6.menu-brief {
    width: 56%;
    padding-right: 0;
  }

  .col-sm-3.menu-price.text-end {
    width: 22%;
  }
}

@media (max-width: 402px) {
  .col-sm-3.menu-img {
    width: 30%;
    padding-right: 0;
  }

  .col-sm-6.menu-brief {
    width: 68%;
    padding-right: 0;
  }

  .col-sm-3.menu-price.text-end {
    width: 100%;
    display: inline-flex;
    padding: 20px 10px 0 52px;
  }

  .dish-menu {
    min-height: 12rem;
  }

  .menu-price h2 {
    padding-right: 24px;
  }
}

/* ==========special-food======== */
.parallax {
  background-image: url("https://backoffice.cloud-bistro.com/uploads/cb-bbq-c1jpg.webp");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Paralax image background color overlay effect */
.parallax::before {
  background-color: rgba(0, 0, 0, 0.55);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.phead {
  color: #fff;
  font-weight: bolder;
}

.paralax-img-part {
  position: relative;
}

.container.paralax-heading {
  position: absolute;
  top: 50%;
  left: 0%;
}

#Padding-X-no {
  padding-right: 0;
  padding-left: 0;
  min-height: 234px;
}

.text.text-center.order-first {
  background: #fff;
  padding: 24px 11px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 0 rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

h2.Special-dish-heading {
  margin-top: 12px;
}

p.Special-dish-brief {
  margin-bottom: 12px;
  line-height: 28px;
  font-weight: 400;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

.Sprice-food {
  color: #f88900;
  font-size: 18px;
  font-weight: bold;
}

.size {
  width: 260px;
  height: 232px;
}

.SpecialFood {
  position: relative;
}

.special-dish {
  position: absolute;
  bottom: -65%;
  left: 0%;
}

@media (max-width: 1092px) {
  .size {
    width: 260px;
    height: 230px;
  }

  #Padding-X-no {
    min-height: 218px;
  }
}

@media (max-width: 1067px) {
  .size {
    width: 260px;
    height: 206px;
  }

  h2.Special-dish-heading {
    margin-top: 0px;
  }

  p.Special-dish-brief {
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  h2.Special-dish-heading {
    margin-top: 0px;
  }

  .size {
    width: 260px;
    height: 197px;
  }

  .text.text-center.order-first {
    min-height: 197px;
    padding: 10px;
  }

  .special-dish {
    position: initial;
    margin-top: 3rem;
  }
}

@media (min-width: 767px) {
  .for-desk-img {
    display: block;
  }

  .for-mobile-img {
    display: none;
  }
}

@media (max-width: 767px) {
  .for-desk-img {
    display: none;
  }

  .for-mobile-img {
    display: block;
  }

  .row.d-flex.no-gutters .col-lg-6.col-sm-12 {
    margin: 10px 0;
  }
}

/* =======Footer====== */
.margintop {
  margin-top: 30rem;
}

@media (max-width: 991px) {
  .margintop {
    margin-top: 3rem;
  }
}

.footer-info {
  background: #0c0c0c;
  padding: 4rem 2rem 2rem 2rem;
}

#frmContact ul li {
  list-style-type: none;
  display: inline-block;
}

.footer-mail {
  text-align: start;
  padding: 1px 1px 23px 33px;
  color: #fff;
}

h2.footer-heading {
  color: #fff;
}

input#txtStayConnected {
  border: 1px solid #313030;
  font-size: 18px;
  width: 100%;
}

input#btnSubmit {
  color: #f8f8f8;
  background: #676767;
  border: 1px solid #333232;
  font-size: 18px;
  text-transform: capitalize;
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
  font-size: 18px;
  color: #fff;
}

address a {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
  font-size: 18px;
  color: #fff;
}

address a:hover {
  color: #fff;
}

.for-contact {
  text-transform: none;
  font-size: 18px;
  color: #fff;
}

a.for-contact:hover {
  color: #fff;
}

.row.footer-bottom {
  background: #000;
  color: #d9d8d8;
}

.for-social-icon img {
  width: 36px;
  padding: 4px 0;
}

.for-social-icon {
  float: left;
  padding: 0px 0px 0px 85px;
}

.for-copyrights {
  float: right;
  padding: 8px 16px 0px 10px;
}

a.footer-link svg {
  color: #ffffff;
  margin: 7px 7px;
  width: 30px;
  height: 30px;
  border-radius: 30%;
  padding: 6px;
}

svg.Finstragm {
  background: radial-gradient(circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%);
}

a.footer-link {
  color: #d3d0d2;
}

input#Femail {
  font-size: 13px;
  border-radius: 0;
}

li.footer-mailbox {
  margin-right: 6px;
}

.result {
  margin-top: 82px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #750707;
  font-size: 18px;
  line-height: 29px;
  color: #750707;
  font-weight: bold;
}

/* =====reservation====== */
/* .parallax1 {
  background-image: linear-gradient(
      0deg,
      rgba(248, 248, 248, 0.11),
      rgba(255, 255, 255, 0.11)
    ),
    url("https://www.cloud-bistro.com/media/common/Cloud_Bistro03.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.rField {
  min-height: 30rem;
}

i.fas.fa-phone-square-alt {
  transform: rotate(90deg);
}

.fas {
  font-size: 16px;
  color: #808080;
}

.far {
  font-size: 16px;
  color: #808080;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: unset !important;
}

.reservation-header {
  font-size: 72px;
  color: #fff;
  line-height: 1.2;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14rem;
}

@media (max-width: 786px) {
  .reservation-header {
    font-size: 59px;
    text-align: center;
  }
}

.img-height {
  height: 520px;
}

@media (max-width: 385px) {
  .img-height {
    height: 390px;
  }
}

.R-header {
  text-align: center;
}

.form-label {
  font-size: 18px;
}

.form-control {
  font-size: 16px;
}

.form-select {
  font-size: 16px;
}

.form-text {
  margin-top: 0.85rem;
  margin-bottom: 0.85rem;
  font-size: 18px;
  color: #6c757d;
}

button.btn.btn-primary.R-done {
  border-radius: 0;
  background: #808080;
  border: 2px solid #808080;
  color: #fff;
  padding: 14px 36px;
  font-size: 18px;
}

button.btn.btn-primary.R-done:hover {
  background: #fff;
  border: 2px solid #808080;
  color: #808080;
  font-weight: bold;
}

.Book_info {
  background: #e2e2e2;
  padding: 12px;
  border: 3px solid #610505;
  border-radius: 4px;
  margin-top: 14px;
  font-size: 16px;
  line-height: 29px;
  color: #610505;
  font-weight: bold;
}

/* =======about======== */
.about-left {
  padding: 0% 7%;
}

.about-header {
  font-size: 40px;
  font-weight: bold;
}

.about-brief {
  text-align: justify;
  font-size: 14px;
  line-height: 26px;
  color: #4f4f4f;
}

@media (max-width: 991px) {
  .about-left {
    margin-top: 5rem;
  }
}

/* =======Menuslug====== */
ol.breadcrumb {
  background: #e8e8eb;
  padding: 7px 10px;
}

li.breadcrumb-item {
  font-size: 18px;
  font-weight: 600;
}

.react-tabs__tab-list {
  border-bottom: 1px #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab--selected {
  background: unset !important;
  border-color: #fff !important;
  border-radius: 0 !important;
  border-bottom: 4px solid #333 !important;
  font-size: 18px;
  font-weight: bold;
}

/* .react-tabs__tab--selected */
.react-tabs__tab {
  border: unset;
  border-bottom: unset;
  width: 30%;
}

.react-tabs__tab-panel--selected {
  border: 2px solid #aaa;
  padding: 32px 28px;
}

.Dish-details {
  margin-bottom: 2rem;
  text-transform: uppercase;
  border: 1px solid transparent;
  padding: 6px 12px;
  cursor: pointer;
  border-bottom: 4px solid #333 !important;
  font-size: 18px;
  font-weight: bold;
}

.Dish-detailsBorder {
  border: 2px solid #aaa;
  padding: 32px 28px;
}

.Rating span {
  color: #ffd9ab;
  font-size: 18px;
}

i.fas.fa-user {
  font-size: 30px;
}

@media (max-width: 991px) {
  .marginTop94 {
    margin-top: 94px;
  }
}

.B-topBottom {
  border-top: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
  margin: 40px 0;
}

.d-ingredients {
  font-weight: 600;
  margin-top: 20px;
}

.d-brief {
  margin-bottom: 22px;
}

.Dish-brief {
  margin-bottom: 10px;
}

.Add-review {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
}

label.form-label.for-review {
  font-size: 18px;
}

.form-text1 {
  color: #6c757d;
}

.form-control.for-review {
  font-size: 12px;
}

@media (max-width: 488px) {
  .react-tabs__tab--selected {
    font-size: 13px;
    width: 35%;
  }

  .react-tabs__tab {
    font-size: 13px;
    width: 35%;
  }
}

/* ======Contact-us====== */
.form-control.for-msz {
  font-size: 14px;
  margin: 10px 0;
}

textarea#message {
  width: 100%;
}

.chead {
  text-align: center;
  margin: 35px 0;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 12px;
}

.cbody {
  color: #bf9f60;
  text-align: center;
}

.ctime {
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin-bottom: 18px;
}

.cnote {
  text-align: center;
  color: #fff;
}

.Contact-us {
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.66),
      rgba(2, 2, 2, 0.47)),
    url("https://backoffice.cloud-bistro.com/uploads/MG_2053.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.paddingT {
  padding-top: 4rem;
}

.cbutton button.btn.btn-warning {
  border: 2px solid #808080;
  color: #808080;
  float: right;
}

.cbutton button.btn.btn-warning:hover {
  color: #fff;
  background: #000;
}

.mapheader {
  text-decoration: underline;
  font-weight: bold;
  color: #fff;
  text-decoration-color: #fff;
}

.map-padding {
  padding: 2px 22px;
}

.mapborder {
  border: 3px solid #fff;
  padding: 8px;
}

@media (max-width: 433px) {
  .Contact-us .container.mt-5.pt-5 .row.p-5 {
    padding: 0 !important;
  }
}

/* ======BlogPage====== */
.recent-blog-post {
  position: relative;
}

.recent-blog-post .blog-recent::after {
  content: "";
  background: #808080;
  position: absolute;
  width: 70%;
  height: 4px;
  top: 38%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 4px;
}

.mtop6rem {
  margin-top: 7rem;
}

@media (max-width: 991px) {
  .mtop6rem {
    margin-top: 6rem;
  }

  .extra-mtop2rem {
    margin-top: 2rem;
  }
}

.latest-blog {
  width: 100%;
  padding: 8px 0;
}

.bDesc {
  padding: 10px 0;
}

.bTitle {
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bdate {
  font-size: 16px;
  color: #7b7777;
}

.bbrief {
  font-size: 15px;
  color: #5f5858;
  text-align: justify;
}

blockquote {
  margin: 2rem 0 2rem 8rem;
  text-align: justify;
  color: #5f5858;
}

i.fas.fa-quote-right {
  font-size: 40px;
  color: #808080;
  padding: 5px;
}

.mTop {
  margin-top: 5rem;
}

.bp-sIcon a .fab {
  font-weight: 400;
  font-size: 22px;
  background: #808080;
  border: 1px solid #808080;
  margin: 3px;
  padding: 16px 20px;
  color: #fff;
}

.bp-sIcon a .fab:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

.form-check-input:checked {
  background-color: #808080;
  border-color: #808080;
}

.form-check-input[type="checkbox"] {
  margin-top: 6px;
  border-radius: 0;
}

.bp-button button.btn.btn-warning {
  border: 2px solid #808080;
  color: #fff;
  background: #808080;
  font-weight: 400;
}

.bp-button button.btn.btn-warning:hover {
  border: 2px solid #000;
  color: #000;
  background: #fff;
  font-weight: bold;
}

/* ====allblog=== */
.blog-div {
  padding: 16px;
}

.blog-content {
  padding: 2px 10px;
  box-shadow: inset 0 -3em 3em rgb(0 0 0 / 3%), 0 0 0 2px rgb(255 255 255),
    0.3em 0.3em 1em rgb(0 0 0 / 32%);
}

.blog-date {
  color: #a7a1a1;
  font-weight: bold;
}

.blog-heading {
  color: #404044;
  font-size: 20px;
  font-weight: 500;
}

.blog-link {
  color: #a7a1a1;
  font-weight: bold;
  font-size: 16px;
}

.blog-cmnt-icon {
  width: 20px;
  float: left;
}

.blog-div {
  transition: 0.2s;
}

.blog-div:hover {
  transform: scale(1.1);
}

.styles_scroll-to-top__2A70v {
  border-radius: 50%;
  box-shadow: 0 9px 25px 0 rgb(6 6 7 / 28%);
  border: 1px solid #808080;
}

@media (max-width: 742px) {
  .p-5.cnct-us {
    padding: 0rem !important;
  }
}

/* ========interior======= */
.interiorImg {
  box-shadow: 0px 18px 20px -11px rgb(1 1 1);
}

.col-lg-5.col-sm-12.p-2 .ImgViewer {
  padding-top: 79.5%;
}

.col-lg-3.col-sm-12.p-2 .ImgViewer {
  padding-top: 99%;
}

.col-lg-6.col-sm-12.p-2 .ImgViewer {
  padding-top: 47.5%;
}

.iHead {
  text-align: justify;
  letter-spacing: 1px;
}

.iHead svg {
  font-size: 48px;
  color: #000;
  padding-bottom: 6px;
}

.bm-item .menu-item {
  color: #fff;
  background: #808080;
}

/* ======onload offer====== */
/* .col-xl-10.col-lg-10.col-md-10.col-sm-10.col-11.left {
  position: relative;
}
.modalPart {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 35%;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff0;
  background-clip: padding-box;
  border: unset!important; 
border-radius: unset!important;
outline: unset!important;
}
.modal-header {
  display: flex;
  padding: 1rem 1rem;
  border-bottom: unset!important;
  border-top-left-radius: unset!important;
  border-top-right-radius: unset!important;
}
.modal-header .btn-close {
  padding: 0;
  font-size: 21px;
} */
/* =========offer & opening time====== */
@media (max-width: 728px) {
  .offers-openingTime {
    margin-top: 6rem;
  }
}

@media (max-width: 323px) {
  .offers-openingTime {
    margin-top: 12rem;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.Opening-border {
  border: 2px solid #000;
  min-height: 161px;
}

.Openbody {
  padding: 10px 0px;
}

.Openhead {
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  margin: 4px 0px;
  text-align: center;
}

.OpeningTime {
  position: relative;
  padding-right: 40px;
}

.OpenReserved {
  position: relative;
  padding-left: 40px;
}

.Opening-border i.far.fa-clock {
  color: #000;
  font-size: 50px;
  position: absolute;
  top: 1.5%;
  left: 38%;
  background: #fffffffa;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 4px;
  border: 1px solid #000;
}

img.reserved {
  position: absolute;
  top: 4%;
  left: 46%;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  padding: 0 3px;
}

@media (max-width: 576px) {
  .Opening-border i.far.fa-clock {
    left: 41%;
  }

  img.reserved {
    left: 41%;
  }
}

h2.Openhead-txt {
  font-weight: bold;
  font-size: 24px;
  padding: 6px 0 3px 0;
  text-transform: capitalize;
}

.Openbody button.btn {
  color: #000;
  border: 1px solid #808080;
  font-size: 20px;
  font-weight: 500;
  -webkit-box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  -moz-box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  box-shadow: 0px 1px 20px -12px rgb(0 0 0);
}

.Openbody button.btn:hover {
  color: #fff;
  background: #808080;
  /* border: 2px solid #808080; */
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 697px) {
  .OpeningTime {
    padding-right: 5px;
  }

  .OpenReserved {
    padding-left: 5px;
  }
}

/* =====meuIndex===== */
.menu-Index {
  width: 100%;
  background-color: #fff;
  padding: 15px 1px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  -moz-box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  box-shadow: 0px 1px 20px -12px rgb(0 0 0);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.mIndex-brief {
  background: url("https://backoffice.cloud-bistro.com/uploads/boder2.jpg");
  width: 100%;
  height: auto;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 1px;
  /* background-size: cover; */
  text-transform: capitalize;
}

@media (max-width: 714px) {
  .mIndex-brief {
    background: #fff;
  }
}

/* .mIndex-brief h3{ position: relative ;}
.menu-Index::after {
  content: "";
  width: 35%;
  position: absolute;
  left: 13px;
  bottom: 8px;
  border: 1px dashed #000;
  border-width: 0 0 1px 0;
}
.menu-Index::before {
  content: "";
  width: 35%;
  position: absolute;
  top: 19px;
  right: 13px;
  border: 1px dashed #000;
  border-width: 1px 0 0 0;
} */
@media (min-width: 1200px) {
  .mIndex-brief h3 {
    font-size: 1.5rem;
  }
}

/* .offerPart {
  background: rgb(131,58,180);
background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
} */
/* ========Popular & Special item========== */
.margintop {
  margin-top: 24rem;
}

@media (max-width: 991px) {
  .margintop {
    margin-top: 0rem;
  }
}

.slick-next:before,
.slick-prev:before {
  font-size: 32px;
  line-height: 1;
  opacity: 0.75;
  color: #11333c;
  margin-left: -11px;
}

.slick-next:before {
  margin-left: -2px;
}

.slick-prev:before {
  margin-left: -11px;
}

.slick-div {
  padding: 16px;
}

.slick-content {
  padding: 2px 10px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.034),
    0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.32);
}

p.Slick-date {
  color: #a7a1a1;
  font-weight: bold;
}

.slick-cmnt-icon {
  width: 20px;
  float: left;
}

.slick-heading {
  color: #404044;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Slick-link {
  color: #a7a1a1;
  font-weight: bold;
  font-size: 16px;
}

.Slick-link:hover {
  color: #11333c;
}

@media (max-width: 767px) {
  .slick-prev:before {
    margin-left: 10px;
  }

  .slick-next:before {
    margin-left: -6px;
  }
}

@media (max-width: 575px) {
  .slick-div {
    margin-left: 10px;
  }
}

/* ---offer */
.Offer {
  position: relative;
  justify-content: center;
}

.Offer-body {
  position: absolute;
  top: 32%;
  left: 16%;
  width: 46%;
  color: #fff;
}

.Offer-body h2 {
  font-size: 40px;
  text-decoration: underline;
}

.Offer-body p {
  font-family: "Courgette", cursive;
  font-size: 26px;
  line-height: 40px;
}

.img80 {
  width: 80%;
  /* align-items: center; */
  /* justify-content: center; */
  border: 10px solid #9d4f05;
  padding: 0;
}
/* ================MenuList============= */
.MenuList {
  border: 1px solid #fff;
  background: #fff;
  padding: 12px;
  border-radius: 6px;
  margin-top: 16px;
  margin-bottom: 30px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 2px;
}
.MenuList h2 {
  text-align: center;
  font-size: 30px;
  line-height: 39px;
  font-weight: 600;
  /* margin: 30px 0 0 0; */
  padding: 30px 0 30px 0;
  background: #efd9bc;
}
.MenuList_Food {
  margin-bottom: 38px;
  padding-left: 35px;
  padding-right: 35px;
}
.MenuList_Food p {
  font-size: 16px;
  line-height: 31px;
  font-weight: 500;
}
.MenuList_SubCat {
  padding-left: 40px;
  padding-right: 40px;
}
.MenuList_SubCat h5 {
  font-size: 24px;
  line-height: 31px;
  font-weight: 500;
  margin-top: 42px;
  margin-bottom: 14px;
}
.MenuList_SubCat h5:first-child {
  margin-top: 0px;
}
.MenuList_SubCat p {
  font-size: 16px;
  line-height: 31px;
  font-weight: 500;
}
@media (max-width:580px) {
  .MenuList_SubCat p {
    padding: 10px 0;
  }
  .food {
    text-align: left;
  }
  .foodPrice {
    text-align: right;
  }
  .MenuList_SubCat {
    padding-left: 0px;
    padding-right: 0px;
  }
  .MenuList_Food {
    padding-left: 0px;
    padding-right: 0px;
  }
}